html,
body {
  overflow-x: hidden;
}

.tl-attribution {
  display: none;
}

h1,
h2:not(.tl-headline),
h3 {
  font-variant: small-caps;
  margin-top: 0;
}

h4,
h5,
h6 {
  font-family: $font-family-sans-serif;
}

h1,
h2:not(.tl-headline),
h3,
h4,
h5,
h6 {
  small {
    font-family: Garamond, Georgia, "Times New Roman", Times, serif;
    font-style: italic;
    font-variant: normal;
  }
}

h2.tl-headline {
  font-family: $font-family-sans-serif;
}

.vcenter {
  display: inline-block;
  float: none;
  vertical-align: middle;
}

.page-section {
  background-size: cover;
  position: relative;
}

.page-template-template-home .page-section {
  border-bottom: 5px solid $white;
  border-top: 5px solid $white;
}

@media (min-width: $grid-float-breakpoint) {
  .vcenter-wide {
    display: inline-block;
    float: none;
    vertical-align: middle;
  }

  .page-section {
    display: table;
    width: 100%;
    bottom: 1px;
  }
}

.section-header {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;

  &.section-header-bar {
    background-color: $brand-secondary;
    color: #fff;

    small {
      color: #ccc;
    }
  }
}

.section-content {
  padding-bottom: 50px;
}

.blockquote-right {
  border-left: 0;
  border-right: 5px solid #eee;
  font-size: 17.5px;
  margin: 0 0 20px;
  padding: 10px 20px;

  @media (max-width: $grid-float-breakpoint-max) {
    border-left: 5px solid #eee;
    border-right: 0;
  }
}

.top-padding {
  padding-top: 30px;
}
