.trinity-dome-landing {
  height: 100%;

  body {
    background-color: $white;
    color: $black;
    display: table;
    height: 100%;
    width: 100%;
  }
}

.trinity-dome-landing-wrapper {
  display: table-cell;
  text-align: center;
  vertical-align: middle;

  // .xagent-landing-pad
}
