.section-header {
  text-align: center;

  small {
    display: block;
    font-style: italic;
    font-variant: normal;
  }
}

.section-inverted {
  .section-header {
    color: $white;

    h1 small,
    h2 small,
    h3 small {
      color: $white;
    }
  }
}
.section-content {
  .col-sm-4 {
    @media(max-width: $grid-float-breakpoint-max) {
      padding-bottom: 15px;
    }
  }
}

.page-section:not(.section-action) .section-content-block {
  background-color: $white;
  box-shadow: 0 4px 18px 2px rgba(0, 0, 0, .5);

  .block-banner {
    border-bottom: 1px solid $white;
  }

  .block-text {
    border-top: 5px solid $brand-red;
    padding: 20px;

    h2,
    h3,
    h4 {
      margin-top: 0;
    }
  }
}

.page-section.section-timeline {
  border-bottom-color: $brand-secondary;
  border-top-color: $brand-red;

  .section-header {
    padding-bottom: 30px;
  }

  .section-intro {
    padding-bottom: 20px;
  }

  #timeline-embed {
    height: 800px;

    @media (min-width: $grid-float-breakpoint) {
      height: 650px;
    }

    .tl-text-content-container {
      h2.tl-headline {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}
