.footer-nav {
  @extend .list-inline;
}

footer.footer {
  @media (min-width: $grid-float-breakpoint) {
    padding: 75px 0;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    text-align: center;
  }

  .logo {
    img {
      max-width: 100%;

      @media (max-width: $grid-float-breakpoint-max) {
        max-width: 250px;
      }
    }
  }
}

.section-action {
  background-color: $action-banner-bg-color;
  overflow: hidden;
  position: relative;

  @media (min-width: $grid-float-breakpoint) {
    background: $action-banner-bg-color url('https://www.trinitydome.org/wp-content/uploads/2017/02/background-mosaic-rendering-to-blue-2.jpg') center top no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
  }

  a {
    color: $white;
  }

  @media (min-width: $grid-float-breakpoint) and (max-width: $screen-lg-min) {
    background-size: 100% auto;
  }

  @media (min-width: $screen-lg-min) {
    background-size: $screen-lg-min auto;
  }

  .section-action-bg1 {
    @include gradient-vertical($action-banner-bg-color, transparentize($action-banner-bg-color, 1));
    height: 200px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .section-action-bg2 {
    @include gradient-vertical(transparentize($action-banner-bg-color, 1), $action-banner-bg-color);
    left: 0;
    position: absolute;
    right: 0;
  }

  .section-header {
    color: $white;

    h1 small,
    h2 small,
    h3 small {
      color: $white;
    }
  }

  .section-content {
    color: $white;
    text-align: center;

    @media (min-width: $grid-float-breakpoint) and (max-width: $screen-sm-max) {
      padding-top: 200px;
    }
  }
}

.footer-share {
  padding-bottom: 14px;
  padding-top: 14px;
}

.form-text {
  @media (min-width: $grid-float-breakpoint) and (max-width: $screen-md-max) {
    margin-bottom: 37px;
  }
  @media (min-width: $screen-lg-min) {
    margin-bottom: 17px;
  }
}
