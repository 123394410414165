// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.frm_ajax_loading {
  visibility: hidden;
  width: auto;
}

.frm_error {
  color: $brand-danger;
}

.form-group {
  margin-top: 15px;
}

.footer-contact-form {
  .form-group {
    margin-top: 5px;
  }

  .form-group.label-floating label.control-label,
  .form-control {
    color: $white;
  }

  .form-group label.control-label {
    margin-left: -50px;
  }

  .form-group.is-focused label.control-label {
    margin-left: 0;
    top: -20px;
  }

  .form-group.frm_error .help-block,
  .form-group.frm_error label.control-label,
  .form-group.has-error .help-block,
  .form-group.has-error label.control-label {
    color: $brand-danger;
  }

  .frm_submit {
    input {
      margin-left: 25px;
    }
  }
}

.frm_message {
  @extend .alert;
  @extend .alert-success;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 20px;
}

.donate,
.press {
  .frm_submit {
    display: inline-block;
    margin-left: 28px;
  }

  h3 {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .section-content-block {

    .block-banner {
      border-bottom: 1px solid $white;
    }

    .block-text {
      border-top: 5px solid $brand-red;
      padding-bottom: 20px;
      padding-top: 20px;

      h2,
      h3,
      h4 {
        margin-top: 0;
      }
    }
  }
}

.donate-form {
  .option-labels {
    label {
      color: $black;
    }
  }

  .no-space {
    margin-top: 0;
  }

  .radio-inline {
    padding-left: 0;
  }

  .radio {
    label {
      @extend .btn;
      @extend .btn-sm;
      @extend .btn-raised;
      @extend .btn-primary;
      color: $white;
    }

    .circle,
    .check,
    input {
      display: none;
    }
  }

  .amount-selected {
    @extend .btn;
    @extend .btn-raised;
    @extend .btn-default;
  }
  .disclaimer {
    display: flex;

    small {
      margin-left: 28px;
    }
  }
}

.selectize-control.single .selectize-input {
  font-size: 16px;
  line-height: 38px;
}

.selectize-control.multi .selectize-input.input-active:after, .selectize-control.multi .selectize-input:after,
.selectize-control.single .selectize-input.input-active:after, .selectize-control.single .selectize-input:after {
  font-family: FontAwesome;
  content: "";
}

.subscribe {
  .frm_submit {
    display: inline-block;
  }
}
