.header-wrapper {
  position: relative;
}

.header-main {
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;
  z-index: 9111;
}

.navbar-header {

  .brand img {
    @media(max-width: $grid-float-breakpoint-max) {
      max-width: 300px;
    }
  }
  .navbar-toggle {
    background-color: $brand-secondary;

    .icon-bar {
      background-color: $white;
    }
  }
}

@media (min-width: $grid-float-breakpoint) {
  /*
  .header-wrapper:before {
    @include gradient-vertical-three-colors($white, transparentize($white, .1), 60%, transparentize($white, 1));
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 240px;
    display: block;
    content: '';
  }
  */

  .header-main .container {
    display: table;
  }

  .navbar-collapse.collapse {
    display: table-cell !important;
    vertical-align: middle;
  }
}

.mobVegas {
  position: relative;
}

.vegas-slide {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.vegas-slide-inner {
  height: 100%;
}

.banner {
  background-color: $brand-secondary;
  color: $white;
  font-size: $font-size-large;
  padding: 40px 0;
  text-align: center;
  z-index: 9999;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    small {
      color: $white;
    }
  }
}

.banner-home {
  background-color: transparent;
  bottom: -10%;
  left: 0;
  min-height: 140px;
  position: absolute;
  right: 0;

  @media (min-width: $grid-float-breakpoint) {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
  }
}

.banner-caption {
  background-color: transparentize($white, .1);
  box-shadow: 0 4px 18px 2px rgba(0, 0, 0, .5);
  color: #000;
  font-size: $font-size-large;
  padding: 15px;
  text-align: center;

  .caption-link {
    margin-bottom: 0;
    margin-top: 0;
  }

  .btn {
    transition: none;
    will-change: unset;
  }

  @media (max-width: $grid-float-breakpoint-max){
    font-size: $font-size-small;
    padding: 15px;
  }

  p {
    font-family: $font-family-serif;
  }
}

.section-slider {
  border-bottom-color: $basilica-banner-bg-color;
  border-top-width: 0;
}

@media (min-width: $screen-sm-min) {
  .brand {
    display: block;
    margin: 0 auto;
    max-width: 450px;

    img {
      max-width: 100%;
    }
  }
}

.fa.fa-2x {
  top: -4px;
  position: relative;
}

.socials-footer .fa.fa-2x {
  bottom: -6px;
  top: auto;
}

.navbar-btn {
  font-weight: normal;
  margin-top: -10px;
  padding: 2px 12px;
}

.banner-controls {
  text-align: center;

  .control {
    position: relative;
    display: inline-block;
    padding: 15px 8px;
    opacity: .25;

    &:before {
      display: block;
      height: 10px;
      width: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: $white;
      margin-left: -5px;
      margin-top: -5px;
      content: '';
    }

    &.active {
      opacity: 1;
    }
  }
}
