.media {
  .media-single {
    background-color: #eee;
    padding: 20px;
    width: 100%;
  }

  .media-caption {
    color: $gray-dark;
    font-size: .85em;
  }

  .media-image {
    img {
      border: none;
      width: 100%;
    }
  }

  .media-buttons {
    padding-bottom: 10px;
    padding-top: 10px;

    .btn {
      margin-bottom: 0;
      margin-top: 0;

      @media (min-width: $grid-float-breakpoint) and (max-width: $screen-md-max) {
        margin-bottom: 10px;
      }
    }
  }
}

.news-media {
  .media-object {
    max-width: 80px;
  }
}

.social-media {
  background-color: $brand-gray;
}

.social-posts {
  .news-social-icons {
    display: inline-block;
    float: left;
    margin-top: 10px;
  }

  .social-post {
    margin-left: 40px;
    margin-bottom: 10px;
  }

  .social-post-message {
    position: relative;
    background-color: #eee;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 3px;

    &:before {
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;

      border-right:10px solid #eee;

      position: absolute;
      top: 10px;
      left: -10px;

      content: '';
      display: block;
    }
  }

  .social-post-meta {
    font-size: .8em;
    color: $gray-light;

    a {
      color: $gray-light;
    }
  }

  .social-post-time,
  .social-post-link {
    display: inline-block;
  }
}

.facebook-post .facebook-icon {
  color: #3b5998;
}

.twitter-post .twitter-icon {
  color: #1da1f2;
}

.socials {
  .social-center {
    display: inline-block;
  }

  &:after {
    clear: both;
  }

  .social-container {
    float: left;
    padding-right: 10px;
    padding-bottom: 10px;

    &:last-child {
      padding-right: 0;
    }
  }
}

.blog,
.press {
  .page-section:not(.section-action) .section-content-block {
    background-color: $white;
    box-shadow: none !important;
  }

  .post {
    line-height: ceil(($line-height-base * 1.1));
    padding-bottom: 30px;
    padding-top: 30px;

    img {
      border-bottom: 5px solid $brand-red;
    }

    header {
      border-bottom: 3px solid $gray-light;
      margin-bottom: 10px;
    }
    .entry-content {
      @include make-row();
    }
    &:nth-child(odd) {
      .entry-media {
        @include make-sm-column(5);
      }

      .entry-summary {
        @include make-sm-column(7);
      }
    }

    &:nth-child(even) {
      .entry-media {
        @include make-sm-column(5);
        @include make-sm-column-push(7);
      }

      .entry-summary {
        @include make-sm-column(7);
        @include make-sm-column-pull(5);
      }
    }
  }
}

.single-post {
  .post {
    line-height: ceil(($line-height-base * 1.1));
    padding-bottom: 30px;
    padding-top: 20px;

    img {
      border-bottom: 5px solid $brand-red;
      @extend .img-responsive;
    }
    h4 {
      color: $brand-red;
      padding-top: 15px;
    }
  }
}
