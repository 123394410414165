// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

body {
  background-color: $body-bg;
  &.inverse {
    background: #333333;
    &, .form-control {
      color: $mdb-text-color-light;
    }
    .modal,
    .panel-default,
    .card {
      &,
      .form-control {
        background-color: initial;
        color: initial;
      }
    }

  }
}

h5, h6{
  font-weight: 400;
}

a, a:hover, a:focus {
  color: $link-color;

  & .material-icons {
    vertical-align: middle;
  }
}

@import 'form';
@import 'welljumbo';
@import 'buttons';
@import 'checkboxes';
@import 'togglebutton';
@import 'radios';
@import 'inputs';

legend {
  border-bottom: 0;
}

@import 'lists';
@import 'navbar';

.dropdown-menu {
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  .divider {
    background-color: rgba(0, 0, 0, .12);
  }
  li {
    overflow: hidden;
    position: relative;
    a:hover {
      background-color: transparent;
      color: $brand-primary;
    }
  }
}

@import 'alerts';
@import 'progress';
@import 'typography';
@import 'tabs';
@import 'popups';
@import 'cards';
@import 'dialogs';
@import 'panels';
@import 'dividers';

// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: 0;
  }
}


@import 'themes';

// External plugins
@import 'plugins';
