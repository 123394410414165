$body-bg: #fff;

// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:      rgba(35, 119, 186, 1);     // 2377BA
$brand-secondary:    rgba(39, 36, 96, 1);       // 271860
$brand-red:          rgba(158, 24, 39, 1);      // 9E1827
$brand-gray:         rgba(153, 153, 153, 1 );   // 999999

$brand-info:         $brand-secondary;
$brand-danger:       $brand-red;

$black:              #000000;
$rgb-black:          "0,0,0" !default;
$white:              #ffffff;
$rgb-white:          "255,255,255" !default;

$font-family-serif: "trajan-pro-3", Garamond, Georgia, "Times New Roman", Times, serif;
$headings-font-family: "trajan-pro-3", Garamond, Georgia, "Times New Roman", Times, serif;

$basilica-banner-bg-color: #00386b;
$action-banner-bg-color: #1e7ab6;

$blockquote-border-color: $brand-secondary;

$border-radius-base: 2px;
