.gallery-slider {
  border-bottom: 5px solid $brand-red;
  top: 0;

  .gallery-headline {
    background: rgba(255,255,255,0.8);
    bottom: 0;
    padding: 10px 25px;
    position: relative;
    width: 100%;

    @media (min-width: $screen-sm-min) {
      bottom: 30px;
      padding: 25px;
      position: absolute;
    }

    h2 {
      color: #000;
      font-size: 18px;
      margin-bottom: 0;
      text-align: center;

      @media (min-width: $screen-sm-min) {
        font-size: 26px;
      }
    }
  }
}

.gallery-slider-image {
  display: block;
  height: auto;
  max-height: 300px;
  max-width: 100%;
  width: 100%;

  @media (min-width: $screen-sm-min) {
    max-height: 500px;
  }
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-prev,
.slick-next {
  bottom: 40%;
  margin-top: auto;
  top: auto;
  z-index: 500;

  @media (min-width: $screen-sm-min) {
    bottom: auto;
    height: 35px;
		top: 50%;
    width: 35px;

		&:before {
			font-size: 35px;
		}
	}
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.envira-album-title {
  color: $brand-red;
  font-family: $font-family-serif;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 2 !important;
  text-align: left;
}

.envira-album-caption {
  line-height: 2 !important;
  text-align: left !important;
}

.envira-gallery-wrap div.caption {
  background-color: rgba(0,0,0,0.7);
  bottom: 0;
  color: #fff;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 300;
  left: 0;
  margin: 0;
  padding: 5px;
  position: absolute;
  right: 0;
  z-index: 2;

  .envirabox-title-over-wrap {
    white-space: pre-line;
    width: 100%;
  }
}

.envira-gallery-item-inner {


  .caption {
    opacity: 0;
  }

  &:hover .caption {
    opacity: 1;
  }
}

.envira-gallery-image {
  border-bottom: 5px solid #9e1827 !important;
}
